import random from 'lodash/random';
import CompanyModel from '@/models/Company';
import JobModel from '@/models/Job';
import trimStart from 'lodash/trimStart';

const homePageStartups = [
  {
    // Lenus e-health
    index: 0,
    id: '58ca8d506dbf85ee7fc3cf2a',
    key: 'lenus-ehealth',
    location: ['DK', 'SE', 'NO', 'FI'],
    imgFile: 'lenus_ehealth-wide.webp'
    // imgFile: 'lenus_ehealth.webp'
  },
  {
    // Too Good To Go
    index: 1,
    id: '5da4758c4c454b717a680267',
    key: 'too-good-to-go',
    location: ['DK', 'SE', 'NO', 'FI'],
    imgFile: 'too_good_to_go-wide.webp'
  },
  {
    // Monta
    index: 2,
    id: '5f9b2087977e6f564e0bf7bb',
    key: 'monta',
    location: ['DK', 'SE', 'NO', 'FI'],
    imgFile: 'monta-wide.webp'
  },
  {
    // Fishbrain
    index: 3,
    id: '57ea37f094b2c6924a4c58b9',
    key: 'fishbrain',
    location: ['DK', 'SE', 'NO', 'FI'],
    imgFile: 'fishbrain-wide.webp'
  },
  {
    // Oda
    index: 4,
    id: '5dc192f175cf61657eed5652',
    key: 'kolonialno',
    location: ['DK', 'SE', 'NO', 'FI'],
    imgFile: 'oda-wide.webp'
  },
  {
    // TechBBQ
    index: 5,
    id: '57a05a90b5c4bd6a1341c923',
    key: 'techbbq',
    location: ['DK', 'SE', 'NO', 'FI'],
    imgFile: 'techbbq-wide.webp'
  },
  {
    // Pleo Technologies
    index: 6,
    id: '5665a7ff0b678aef6bba9666',
    key: 'pleo',
    location: ['DK', 'SE', 'NO', 'FI'],
    imgFile: 'pleo-wide.webp'
    // imgFile: 'pleo.webp'
  },
  {
    // Veo Technologies
    index: 7,
    id: '5c7911c541e3cff76a81de95',
    key: 'veo-technologies',
    location: ['DK', 'SE', 'NO', 'FI'],
    imgFile: 'veo-wide.webp'
  },
  {
    // Strise
    index: 8,
    id: '58c2b1cd62a607ad20cae07d',
    key: 'mitoai',
    location: ['DK', 'SE', 'NO', 'FI'],
    imgFile: 'strise-wide.webp'
  },
  {
    // Droppe
    index: 9,
    id: '5e96e4430aa31106876b43dd',
    key: 'droppe',
    location: ['DK', 'SE', 'NO', 'FI'],
    imgFile: 'droppe-wide.webp'
  },
  // {
  //   // Foodora
  //   index: 10,
  //   id: '65e0e95042251ee908d77480',
  //   key: 'foodora-denmark-1',
  //   location: ['DK'],
  //   imgFile: 'foodora-wide.webp'
  // },
  // {
  //   // IQM
  //   index: 11,
  //   id: '5e5f6ab88e63c058b22f5b74',
  //   key: 'iqm-quantum-computers',
  //   location: ['DK', 'SE', 'NO', 'FI'],
  //   imgFile: 'iqm-wide.webp'
  // },
  {
    // Superb
    index: 10,
    id: '585fd3dcce36ee2935297053',
    key: 'superb',
    location: ['DK', 'SE', 'NO', 'FI'],
    imgFile: 'superb-wide.webp'
  },
  {
    // Supermetrics
    index: 11,
    id: '59ccff3bef09bd797cf38f42',
    key: 'supermetrics',
    location: ['DK', 'SE', 'NO', 'FI'],
    imgFile: 'supermetrics-wide.webp'
  },
  {
    // Voi
    index: 12,
    id: '5fbbb70be940707df779987a',
    key: 'voi-technology',
    location: ['SE'],
    imgFile: 'voi-wide.webp'
  },
  {
    // Reshape
    index: 13,
    id: '606c5a51c5b717064c8bffc9',
    key: 'reshape-biotech',
    location: ['DK'],
    imgFile: 'reshape.webp'
  },
  {
    // Formalize
    index: 14,
    id: '60193c3c759e36706937063e',
    key: 'whistleblower-software',
    location: ['DK', 'SE'],
    imgFile: 'formalize.webp'
  },
  {
    // Memmora
    index: 15,
    id: '630c589599122d25d4dfe607',
    key: 'memmora',
    location: ['DK'],
    imgFile: 'memmora.webp'
  }
];

export const getStartup = ({ id, index }) => {
  try {
    if (!index && !id) {
      const randomIndex = random(0, homePageStartups.length - 1);
      return homePageStartups[randomIndex];
    } else {
      if (index) {
        return homePageStartups[index];
      }
      if (id) {
        return homePageStartups.find((startup) => startup.id == id);
      }
    }
  } catch (err) {
    // error(err);
    console.log(err);
  }
};

export const getStartupsIds = () => {
  try {
    return homePageStartups.map((startup) => startup.id);
  } catch (err) {
    error(err);
  }
};

// id and index props are optional
export const fetchStartup = async ({ id, index, axios }) => {
  // console.log('fetchStartup', { id, index });
  try {
    // console.time('fetchStartup');
    const rawStartup = id ? { id } : getStartup({ index });

    const { data: startupData } = await axios.get('companies/id/' + rawStartup.id, {
      params: {
        crossplatform: true
      }
    });

    const startup = {
      ...rawStartup,
      ...new CompanyModel(startupData)
    };

    startup.extraLogoThumbnailSizeUrl = startup.logoImage?.thumbnailSizeUrl;
    // console.timeEnd('fetchStartup');
    return startup;
  } catch (err) {
    console.log(err);
  }
};

export const fetchStartups = async ({ ids, axios }) => {
  try {
    const { data: startupsData } = await axios.get('home-page-companies/', {
      params: {
        ids: ids || getStartupsIds()
      }
    });

    // console.log('startupsData', startupsData);

    const startups = startupsData.map((startupData, index) => {
      const rawStartup = getStartup({ id: startupData.id });
      // console.log(`fetchStartups ${startupData.id}`, rawStartup);
      return {
        ...rawStartup,
        ...startupData,
        ...new CompanyModel(startupData),
        index
      };
    });

    return startups;
  } catch (err) {
    console.log(err);
  }
};

export const fetchFeaturedStartups = async ({ axios, limit }) => {
  try {
    // console.time('fetchFeaturedStartups');
    const { data: startupsData } = await axios.get('home-page-featured-companies/', {
      params: { limit }
    });

    // console.log('fetchFeaturedStartups startupsData', startupsData);

    const startups = startupsData.map((startupData) => {
      return {
        ...startupData,
        logo:
          process.env.IMGIX_BASE_URL +
          '/files/s3/' +
          trimStart(startupData?.logoImage?.file?.filename || '', '/') +
          '?fit=crop&w=200&h=200&auto=format&q=60'
      };
    });

    // console.log('startups', startups);
    // console.timeEnd('fetchFeaturedStartups');
    return startups;
  } catch (err) {
    console.log(err);
  }
};

const getPopularJobLists = (countryCode) => [
  {
    name: 'Trending startup jobs',
    countryCode,
    sorting: 'mostPopular',
    roles: []
  },
  {
    name: 'Engineering  jobs',
    countryCode,
    sorting: 'mostPopular',
    roles: [
      'backenddeveloper',
      'devops',
      'engineer',
      'frontenddeveloper',
      'fullstackdeveloper',
      'mobiledevelopment'
    ]
  },
  {
    name: 'Product  jobs',
    countryCode,
    sorting: 'mostPopular',
    roles: ['productmanagement', 'uxuidesigner']
  },
  {
    name: 'Design  jobs',
    countryCode,
    sorting: 'mostPopular',
    roles: ['design', 'uxuidesigner']
  },
  {
    name: 'Marketing jobs',
    countryCode,
    sorting: 'mostPopular',
    roles: ['marketing']
  },
  {
    name: 'Sales  jobs',
    countryCode,
    sorting: 'mostPopular',
    roles: ['sales', 'customersuccess', 'businessdevelopment']
  },
  {
    name: 'Operations jobs',
    countryCode,
    sorting: 'mostPopular',
    roles: ['operations', 'finance', 'projectmanagement']
  }
];

const parseUrl = (jobList) => {
  let resultUrl = 'jobs?';
  if (jobList.roles && jobList.roles.length > 0) {
    resultUrl += jobList.roles.map((role) => `roles=${role}`).join('&');
  }
  if (jobList.sorting) {
    if (resultUrl.length > 1) {
      resultUrl += '&';
    }
    resultUrl += `sorting=${jobList.sorting}`;
  }
  if (jobList.countryCode) {
    if (resultUrl.length > 1) {
      resultUrl += '&';
    }
    resultUrl += `countryCode=${jobList.countryCode}`;
  }
  return resultUrl;
};

const fetchJobs = async ({ axios, params }) => {
  // Handle remote countryCode and remote jobs
  if (params.countryCode === 'REMOTE') {
    delete params.countryCode;
    params.isRemote = true;
  }
  // console.log('params', params);
  const { data } = await axios.get('/home-page-jobs', {
    params
  });

  // return data.docs.map((item) => new JobModel(item));
  return data.docs;
};

export const fetchPopularJobs = async ({ axios, countryCode }) => {
  // return [];
  try {
    // console.time('fetchPopularJobs');
    const lists = getPopularJobLists(countryCode);

    const fetchedListsPromises = lists.map(async (list) => {
      const jobs = await fetchJobs({
        axios,
        params: {
          countryCode: list.countryCode,
          sorting: list.sorting,
          roles: list.roles,
          limit: 5
        }
      });

      const parsedList = {
        link: parseUrl(list),
        name: list.name,
        countryCode: list.countryCode,
        sorting: list.sorting,
        roles: list.roles,
        jobs
      };

      // console.log('fetchPopularJobs jobs', jobs);
      // console.timeEnd('fetchPopularJobs');
      return parsedList;
    });

    const result = await Promise.all(fetchedListsPromises);
    return result;
  } catch (err) {
    console.log(err);
  }
};

export const homePageInit = async ({ axios, countryCode }) => {
  // return [];
  try {
    const startup = getStartup({});
    // console.time('fetchPopularJobs');
    const lists = getPopularJobLists(countryCode);
    const popularJobFilters = lists.map((list) => {
      return {
        name: list.name,
        countryCode: countryCode,
        sorting: list.sorting,
        roles: list.roles,
        limit: '5'
      };
    });
    // console.log('tests', tests);

    const params = {};
    params.popularJobFilters = popularJobFilters;
    params.featuredCompaniesLimit = '3';
    params.companyId = startup.id;

    const { data } = await axios.get('/home-page-init', {
      params
    });

    // homePageCompany, homePageFeaturedCompanies, homePagePopularJobLists

    // prefetchedStartup,
    // prefetchedActiveFeaturedStartups,
    // prefetchedPopularJobLists

    const prefetchedStartup = {
      ...startup,
      ...data.homePageCompany,
      ...new CompanyModel(data.homePageCompany)
    };

    prefetchedStartup.extraLogoThumbnailSizeUrl = prefetchedStartup.logoImage?.thumbnailSizeUrl;

    const prefetchedActiveFeaturedStartups = data.homePageFeaturedCompanies.map((startupData) => {
      return {
        ...startupData,
        logo:
          process.env.IMGIX_BASE_URL +
          '/files/s3/' +
          trimStart(startupData?.logoImage?.file?.filename || '', '/') +
          '?fit=crop&w=200&h=200&auto=format&q=60'
      };
    });

    const prefetchedPopularJobLists = data.homePagePopularJobLists.map((list) => {
      const parsedList = {
        link: parseUrl(list),
        name: list.name,
        countryCode: list.countryCode,
        sorting: list.sorting,
        roles: list.roles,
        jobs: list.jobs
      };

      // console.log('fetchPopularJobs jobs', jobs);
      // console.timeEnd('fetchPopularJobs');
      return parsedList;
    });

    return { prefetchedStartup, prefetchedActiveFeaturedStartups, prefetchedPopularJobLists };
  } catch (err) {
    console.log(err);
  }
};
